import {
  HallOfFameVotes,
  IBrassMp3AlbumPurchase,
  IBrassMp3Purchase,
  IBrassSheetMusicPurchase,
  IUser,
  IVocalMp3AlbumPurchase,
  IVocalMp3Purchase,
  IVocalSheetMusicPurchase,
} from '../../definitions/user';
import {
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_STARTED,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_TOKEN_EXPIRED,
  CHANGE_PASSWORD_UNAUTHORISED,
  FETCH_USER_DETAIL_RECEIVE,
  FETCH_USER_DETAIL_STARTED,
  FETCH_USER_DETAIL_UNAUTHORISED,
  FETCH_USER_HALL_OF_FAME_VOTES_RECEIVE,
  FETCH_USER_HALL_OF_FAME_VOTES_STARTED,
  FETCH_USER_HALL_OF_FAME_VOTES_UNAUTHORISED,
  FETCH_USER_PURCHASES_RECEIVE,
  FETCH_USER_PURCHASES_STARTED,
  FETCH_USER_PURCHASES_UNAUTHORISED,
  SUBSCRIPTION_VOUCHER_APPLY_BAD_CODE,
  SUBSCRIPTION_VOUCHER_APPLY_CONFLICT,
  SUBSCRIPTION_VOUCHER_APPLY_ERROR,
  SUBSCRIPTION_VOUCHER_APPLY_EXPIRED,
  SUBSCRIPTION_VOUCHER_APPLY_FORBIDDEN,
  SUBSCRIPTION_VOUCHER_APPLY_STARTED,
  SUBSCRIPTION_VOUCHER_APPLY_SUCCESS,
  USER_LOGIN_ERROR,
  USER_LOGIN_STARTED,
  USER_LOGIN_UNAUTHORISED,
  USER_MORPH_ERROR,
  USER_MORPH_STARTED,
  USER_MORPH_UNAUTHORISED,
  USER_REGISTRATION_CONFLICT,
  USER_REGISTRATION_ERROR,
  USER_REGISTRATION_STARTED,
  USER_RESET_PASSWORD_CONFLICT,
  USER_RESET_PASSWORD_ERROR,
  USER_RESET_PASSWORD_STARTED,
  USER_RESET_PASSWORD_SUCCESS,
  USER_UPDATE_PREFERENCES_ERROR,
  USER_UPDATE_PREFERENCES_STARTED,
  USER_UPDATE_PROFILE_EMAIL_CONFLICT,
  USER_UPDATE_PROFILE_ERROR,
  USER_UPDATE_PROFILE_STARTED,
} from '../actions';
import {IAction} from '../store';

export interface IUserState {
  changePasswordData: {
    error: string;
    fetching: boolean;
  };
  hallOfFameVotes: {
    detail?: {
      brass: HallOfFameVotes;
      vocal: HallOfFameVotes;
    };
    error: string;
    fetching: boolean;
  };
  loginData: {
    error: string;
    fetching: boolean;
  };
  morphData: {
    error: string;
    fetching: boolean;
  };
  purchases: {
    detail?: {
      brassPurchases: IBrassSheetMusicPurchase[];
      brassHardCopyPurchases: IBrassSheetMusicPurchase[];
      brassMp3AlbumPurchases: IBrassMp3AlbumPurchase[];
      brassMp3Purchases: IBrassMp3Purchase[];
      vocalPurchases: IVocalSheetMusicPurchase[];
      vocalHardCopyPurchases: IVocalSheetMusicPurchase[];
      vocalMp3AlbumPurchases: IVocalMp3AlbumPurchase[];
      vocalMp3Purchases: IVocalMp3Purchase[];
    };
    error: string;
    fetching: boolean;
  };
  registrationData: {
    error: string;
    fetching: boolean;
  };
  resetPasswordData: {
    error: string;
    fetching: boolean;
  };
  subscriptionVoucherData: {
    error: string;
    fetching: boolean;
    success: boolean;
  };
  updateData: {
    error: string;
    fetching: boolean;
  };
  userData: {
    detail: IUser | null;
    fetching: boolean;
  };
}

export const initialState: IUserState = {
  changePasswordData: {
    error: '',
    fetching: false,
  },
  hallOfFameVotes: {
    error: '',
    fetching: false,
  },
  loginData: {
    error: '',
    fetching: false,
  },
  morphData: {
    error: '',
    fetching: false,
  },
  purchases: {
    error: '',
    fetching: false,
  },
  registrationData: {
    error: '',
    fetching: false,
  },
  resetPasswordData: {
    error: '',
    fetching: false,
  },
  subscriptionVoucherData: {
    error: '',
    fetching: false,
    success: false,
  },
  updateData: {
    error: '',
    fetching: false,
  },
  userData: {
    detail: null,
    fetching: false,
  },
};

export default function userReducer(
  state = initialState,
  action: IAction,
): IUserState {
  switch (action.type) {
    case FETCH_USER_DETAIL_RECEIVE:
      return {
        changePasswordData: {
          error: '',
          fetching: false,
        },
        hallOfFameVotes: {
          error: '',
          fetching: false,
        },
        loginData: {
          error: '',
          fetching: false,
        },
        morphData: {
          error: '',
          fetching: false,
        },
        purchases: {
          error: '',
          fetching: false,
        },
        registrationData: {
          error: '',
          fetching: false,
        },
        resetPasswordData: {
          error: '',
          fetching: false,
        },
        subscriptionVoucherData: {
          error: '',
          fetching: false,
          success: false,
        },
        updateData: {
          error: '',
          fetching: false,
        },
        userData: {
          detail: action.data,
          fetching: false,
        },
      };

    case FETCH_USER_DETAIL_STARTED:
      return {
        ...state,
        userData: {
          detail: null,
          fetching: true,
        },
      };

    case FETCH_USER_DETAIL_UNAUTHORISED:
      return {
        ...state,
        loginData: {
          error: '',
          fetching: false,
        },
        userData: {
          detail: null,
          fetching: false,
        },
      };

    case FETCH_USER_HALL_OF_FAME_VOTES_RECEIVE:
      return {
        ...state,
        hallOfFameVotes: {
          detail: action.data,
          error: '',
          fetching: false,
        },
      };

    case FETCH_USER_HALL_OF_FAME_VOTES_STARTED:
      return {
        ...state,
        hallOfFameVotes: {
          detail: {
            brass: {},
            vocal: {},
          },
          error: '',
          fetching: true,
        },
      };

    case FETCH_USER_HALL_OF_FAME_VOTES_UNAUTHORISED:
      return {
        ...state,
        hallOfFameVotes: {
          detail: {
            brass: {},
            vocal: {},
          },
          error: 'You are not logged in.',
          fetching: true,
        },
      };

    case FETCH_USER_PURCHASES_RECEIVE:
      return {
        ...state,
        purchases: {
          detail: action.data,
          error: '',
          fetching: false,
        },
      };

    case FETCH_USER_PURCHASES_STARTED:
      return {
        ...state,
        purchases: {
          detail: {
            brassPurchases: [],
            brassHardCopyPurchases: [],
            brassMp3AlbumPurchases: [],
            brassMp3Purchases: [],
            vocalPurchases: [],
            vocalHardCopyPurchases: [],
            vocalMp3AlbumPurchases: [],
            vocalMp3Purchases: [],
          },
          error: '',
          fetching: true,
        },
      };

    case FETCH_USER_PURCHASES_UNAUTHORISED:
      return {
        ...state,
        purchases: {
          detail: {
            brassPurchases: [],
            brassHardCopyPurchases: [],
            brassMp3AlbumPurchases: [],
            brassMp3Purchases: [],
            vocalPurchases: [],
            vocalHardCopyPurchases: [],
            vocalMp3AlbumPurchases: [],
            vocalMp3Purchases: [],
          },
          error: 'You are not logged in.',
          fetching: true,
        },
      };

    case USER_LOGIN_UNAUTHORISED:
      return {
        ...state,
        loginData: {
          error: `Sorry, we didn't recognise those credentials. Please try again.`,
          fetching: false,
        },
        userData: {
          detail: null,
          fetching: false,
        },
      };

    case USER_LOGIN_ERROR:
      return {
        ...state,
        loginData: {
          error:
            'Sorry, an error occurred trying to log you in. Please try again later.',
          fetching: false,
        },
        userData: {
          detail: null,
          fetching: false,
        },
      };

    case USER_MORPH_UNAUTHORISED:
      return {
        ...state,
        morphData: {
          error: `Sorry, you are not authorised to morph as this user.`,
          fetching: false,
        },
      };

    case USER_MORPH_ERROR:
      return {
        ...state,
        morphData: {
          error:
            'Sorry, an error occurred trying to morph. Please try again later.',
          fetching: false,
        },
      };

    case USER_REGISTRATION_ERROR:
      return {
        ...state,
        registrationData: {
          error:
            'There was an error processing your registration. Please try again later.',
          fetching: false,
        },
        userData: {
          detail: null,
          fetching: false,
        },
      };

    case USER_REGISTRATION_CONFLICT:
      return {
        ...state,
        registrationData: {
          error: 'This e-mail address is already registered!',
          fetching: false,
        },
        userData: {
          detail: null,
          fetching: false,
        },
      };

    case USER_LOGIN_STARTED:
      return {
        ...state,
        loginData: {
          error: '',
          fetching: true,
        },
      };

    case USER_MORPH_STARTED:
      return {
        ...state,
        morphData: {
          error: '',
          fetching: true,
        },
      };

    case USER_REGISTRATION_STARTED:
      return {
        ...state,
        registrationData: {
          error: '',
          fetching: true,
        },
        userData: {
          detail: null,
          fetching: false,
        },
      };

    case USER_UPDATE_PROFILE_ERROR:
    case USER_UPDATE_PREFERENCES_ERROR:
      return {
        ...state,
        updateData: {
          error:
            'There was an error processing your change. Please try again later.',
          fetching: false,
        },
      };

    case USER_UPDATE_PROFILE_EMAIL_CONFLICT:
      return {
        ...state,
        updateData: {
          error: 'This email address is already registered to another user',
          fetching: false,
        },
      };

    case USER_UPDATE_PROFILE_STARTED:
    case USER_UPDATE_PREFERENCES_STARTED:
      return {
        ...state,
        updateData: {
          error: '',
          fetching: true,
        },
      };

    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordData: {
          error:
            'There was an error updating your password. Please try again later.',
          fetching: false,
        },
      };

    case CHANGE_PASSWORD_STARTED:
      return {
        ...state,
        changePasswordData: {
          error: '',
          fetching: true,
        },
      };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordData: {
          error: 'Password changed successfully',
          fetching: false,
        },
      };

    case CHANGE_PASSWORD_UNAUTHORISED:
      const error = action?.data?.token
        ? 'Token not recognised. Please click the link sent to your email address'
        : 'Your password is incorrect';

      return {
        ...state,
        changePasswordData: {
          error,
          fetching: false,
        },
      };

    case CHANGE_PASSWORD_TOKEN_EXPIRED:
      return {
        ...state,
        changePasswordData: {
          error:
            'Your token has expired. Please use the forgotten password form again',
          fetching: false,
        },
      };

    case USER_RESET_PASSWORD_CONFLICT:
      return {
        ...state,
        resetPasswordData: {
          error: 'This email address is not registered',
          fetching: false,
        },
      };

    case USER_RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordData: {
          error:
            'There was an error resetting your password. Please try again later.',
          fetching: false,
        },
      };

    case USER_RESET_PASSWORD_STARTED:
      return {
        ...state,
        resetPasswordData: {
          error: '',
          fetching: true,
        },
      };

    case USER_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordData: {
          error:
            'An email has been sent to you containing a link to reset your password.',
          fetching: false,
        },
      };

    case SUBSCRIPTION_VOUCHER_APPLY_BAD_CODE:
      return {
        ...state,
        subscriptionVoucherData: {
          error:
            'We did not recognise this voucher code. Please check and try again.',
          fetching: false,
          success: false,
        },
      };

    case SUBSCRIPTION_VOUCHER_APPLY_FORBIDDEN:
      return {
        ...state,
        subscriptionVoucherData: {
          error: 'This voucher code has already been applied.',
          fetching: false,
          success: false,
        },
      };

    case SUBSCRIPTION_VOUCHER_APPLY_CONFLICT:
      return {
        ...state,
        subscriptionVoucherData: {
          error:
            'You have already applied a subscription voucher that contains the same journals as this one. Please use this voucher on a separate account.',
          fetching: false,
          success: false,
        },
      };

    case SUBSCRIPTION_VOUCHER_APPLY_EXPIRED:
      return {
        ...state,
        subscriptionVoucherData: {
          error:
            "This voucher is for a previous year's journals, and cannot be automatically applied. Please contact us for further support.",
          fetching: false,
          success: false,
        },
      };

    case SUBSCRIPTION_VOUCHER_APPLY_ERROR:
      return {
        ...state,
        subscriptionVoucherData: {
          error:
            'There was an error applying your voucher. Please try again later.',
          fetching: false,
          success: false,
        },
      };

    case SUBSCRIPTION_VOUCHER_APPLY_STARTED:
      return {
        ...state,
        subscriptionVoucherData: {
          error: '',
          fetching: true,
          success: false,
        },
      };

    case SUBSCRIPTION_VOUCHER_APPLY_SUCCESS:
      return {
        ...state,
        subscriptionVoucherData: {
          error: '',
          fetching: false,
          success: true,
        },
      };

    default:
      return state;
  }
}
